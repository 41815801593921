import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import NetworkContext from '../context/network-context';
import { computeTrade } from '../utils/utils';
import { Tokens } from '../web3/contracts/contracts';


function ExchangeConfirm({ mode, action, quoteValue, baseValue, quote, base, price }) {
    const { pendingTransaction } = useContext(NetworkContext);

    const [minAmount, setMinAmount] = useState(0);
    const [slippage, setSlippage] = useState();
    const [slippageAuto, setSlippageAuto] = useState(true);

    const [warning, setWarning] = useState(null);

    const calclateAutoSlippage = useCallback(async () => {
        let path;
        if (quote.symbol !== Tokens.BNB.symbol && base.symbol !== Tokens.BNB.symbol) {
            path = [base.address, Tokens.BNB.address, quote.address];
        } else {
            path = [base.address, quote.address];
        }

        const trade = await computeTrade(+baseValue, path);
        return +(+trade.priceImpact.toFixed(2) + (0.3 * path.length)).toFixed(2);
    }, [baseValue, quote, base]);

    const checkValid = useCallback((slippage) => {
        if (slippage > 5) {
            setWarning('Warning! High slippage can lead to an extremely unfavorable exchange price.');
            return;
        }
        if (slippage < 0.2) {
            setWarning('Warning! Transaction may fail.');
            return;
        }
        setWarning(null);
    }, []);


    const updateSlippage = useCallback(async (slippage) => {
        if (slippage === 'auto') {
            slippage = await calclateAutoSlippage();
            setSlippageAuto(true);
        } else {
            setSlippageAuto(false);
        }

        if (slippage > 50) {
            slippage = 50;
            setWarning('Maximum slippage is 50.');
        }

        setSlippage(slippage);
        checkValid(slippage);
    }, [setSlippage, checkValid, calclateAutoSlippage]);


    useEffect(() => {
        updateSlippage('auto');
    }, [updateSlippage]);

    useEffect(() => {
        const tax = base.symbol === Tokens.HPAY.symbol ? 12 : 10;
        const _minAmount = quoteValue - (quoteValue * ((+slippage + tax) / 100));
        setMinAmount(_minAmount);
    }, [slippage, quoteValue, base]);


    return (
        <div className="staking-input-component">
            <Card>
                <Card.Body>
                    <Row style={{ marginBottom: '1rem' }} noGutters>
                        <Col md={12} className=" text-center ">
                            <h3 className="text-white ">Exchange</h3>
                        </Col>
                    </Row>
                    <Row className="exchange-confirm-summary" >
                        <Col md={12} className="mb-4 d-flex justify-content-between">
                            <div className="exchange-group">
                                <img
                                    src={'/' + base.symbol.toLowerCase() + '.svg'}
                                    alt={base.symbol}
                                    width={40}
                                    height={40}
                                />
                                <div className="exchange-group-input">
                                    <small className="exchange-confirm-value-label">From</small>
                                    <span className="exchange-confirm-value-item ">
                                        <NumberFormat value={baseValue} displayType={'text'} thousandSeparator={true} />
                                    </span>
                                </div>
                            </div>
                            <span className="exchange-confirm-currency " >{base.symbol} </span>
                        </Col>

                        <Col md={12} className="mb-4 d-flex justify-content-between">

                            <div className="exchange-group">
                                <img
                                    src={'/' + quote.symbol.toLowerCase() + '.svg'}
                                    alt={quote.symbol}
                                    width={40}
                                    height={40}
                                />
                                <div className="exchange-group-input">
                                    <small className="exchange-confirm-value-label">To</small>
                                    <span className="exchange-confirm-value-item ">
                                        <NumberFormat value={quoteValue} displayType={'text'} thousandSeparator={true} />
                                    </span>
                                </div>
                            </div>
                            <span className="exchange-confirm-currency" >{quote.symbol} </span>
                        </Col>
                    </Row>

                    <Row className="mb-5">
                        <Col md={12} className="d-flex align-items-center mb-3">
                            <small className="exchange-confirm-value-label">Slippage (50% Maximum)</small>
                        </Col>

                        <Col md={12} className="text-right d-flex">
                            <Button className={classNames('slippage-btn', { 'active': slippageAuto === true })} onClick={() => updateSlippage('auto')}>Auto</Button>
                            <Button className={classNames('slippage-btn', { 'active': slippage === 0.5 })} onClick={() => updateSlippage(0.5)}>0.5 %</Button>
                            <Button className={classNames('slippage-btn', { 'active': slippage === 1 })} onClick={() => updateSlippage(1)}>1 %</Button>

                            <InputGroup className="slippage-input">
                                <Form.Control
                                    className="exchange-confirm-input ml-auto text-right"
                                    type="text"
                                    value={slippage}
                                    defaultValue={slippage}
                                    autoComplete="off"
                                    inputMode="decimal"
                                    onChange={e => updateSlippage(e.target.value)}
                                />

                                <InputGroup.Append>
                                    <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col md={4} className="d-flex align-items-center">
                            <small className="exchange-confirm-value-label">Price</small>
                        </Col>

                        <Col md={8} className="text-right">
                            <small className="exchange-confirm-value-label ">
                                <NumberFormat value={mode !== 'buy' ? (1 / price).toFixed(8) : price} displayType={'text'} decimalScale={8} thousandSeparator={true} />  {mode === 'buy' ? <>{quote.symbol}/{base.symbol}</> : <>{base.symbol}/{quote.symbol}</>}
                            </small>
                        </Col>
                    </Row>

                    <Row >
                        <Col md={6} className="d-flex align-items-center">
                            <small className="exchange-confirm-value-label">Minimum Recevied</small>
                        </Col>

                        <Col md={6} className="text-right">
                            <small className="exchange-confirm-value-label mt-0">
                                <NumberFormat decimalScale={4} value={minAmount} displayType={'text'} thousandSeparator={true} /> {quote.symbol}
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={`tooltip-Whatsapp`}>
                                            <strong>All taxes are included</strong>
                                        </Tooltip>
                                    }>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle info-icon" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                                        </svg>
                                    </span>

                                </OverlayTrigger>

                            </small>
                        </Col>
                    </Row>


                    <Button disabled={slippage > 50 || !slippage || pendingTransaction} onClick={() => action(+minAmount.toFixed(8))} className="mt-5" block>Confirm</Button>
                    {warning && <p className="text-center text-warning mt-2 mr-auto ml-auto" >{warning}</p>}
                </Card.Body >
            </Card >
        </div >
    );
}

export default ExchangeConfirm;
