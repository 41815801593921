import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import Exchange from '../components/exchange';
import PresaleStats from '../components/presale-stats';
import ExchangeAddr from "hpay/contracts/Exchange.json";


const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);

    return { isClient, key };
};

function Otc() {
    const { isClient } = useIsClient();

    if (!isClient) { return null; }

    return (
        <div className="py-md-2">
       
            <Row className="mb-2 justify-content-center">

                <Col md={6} className="mb-3">
                    <PresaleStats></PresaleStats>
                </Col>

                <Col md={6} className="mb-3">
                  <Exchange></Exchange>
                </Col>

            </Row>
        </div >
    );
}

export default Otc;
