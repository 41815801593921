import AutoNumeric from 'autonumeric';
import BigNumber from 'bignumber.js';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useInterval } from 'react-use';
import NetworkContext from '../context/network-context';
import { useExchangeRates } from '../state/exchange';
import { getBalance, useApprove } from '../web3/account';
import { ContractAddresses, Tokens } from '../web3/contracts/contracts';
import { useExchangeActions } from '../web3/exchange';
import { useBnbPrice } from '../web3/price-data';
import CoinSelect from './coin-select';
import ExchangeConfirm from './exchange-confirm';


function Exchange({ disabledCoins }) {

    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);

    const baseInput = useRef(null);
    const quoteInput = useRef(null);

    const [numericHandles, setnumericHandles] = useState(null);
    const [base, setBase] = useState('BNB');
    const [quote, setQuote] = useState('HPAY');
    const [balance, setBalance] = useState(0);
    const [bnbBalance, setBNBBalance] = useState(0);


    const [showConfirm, setShowConfirm] = useState(false);
    const [modalStatus, setModalStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState();
    const [isValid, setIsValid] = useState(false);


    const handleClose = useCallback(() => {
        if (pendingTransaction) {
            return;
        }
        setShowConfirm(false);
    }, [setShowConfirm, pendingTransaction]);


    const [price, refreshPrice] = useExchangeRates(Tokens[base], Tokens[quote]);
    useInterval(refreshPrice, 10000);

    const { buy, sell } = useExchangeActions(account);
    const { approve, isApproved } = useApprove(Tokens[base].address, account, ContractAddresses.EXCHANGE);
    const bnbPrice = useBnbPrice();

    const refreshBalance = useCallback(async () => {
        const balance = await getBalance(base, account);
        setBalance(balance || 0);
        const bnbBalance = await getBalance('BNB', account);
        setBNBBalance(bnbBalance || 0);
    }, [setBalance, base, account]);

    const swap = async () => {
        let tx;
        const baseValue = numericHandles.baseInput.getNumber().toFixed(8);
        const quoteValue = numericHandles.quoteInput.getNumber().toFixed(8);
        const baseToken = Tokens[quote];
        const quoteToken = Tokens[base];
        if (base !== 'HPAY') {
            setModalStatus({
                mode: 'buy',
                balance,
                quoteValue,
                baseValue,
                quote: baseToken,
                base: quoteToken,
                price,
                action: async (minAmount) => {
                    tx = async () => await buy(baseValue, minAmount, base);
                    try {
                        await executeTransaction({
                            message: 'Executing Buy',
                            tx
                        });
                    } catch (error) {
                        console.log(error);
                    }
                    refreshBalance();
                    refreshPrice();
                    setShowConfirm(false);
                }
            });
        } else {
            setModalStatus({
                mode: 'sell',
                balance,
                quoteValue,
                baseValue,
                quote: baseToken,
                base: quoteToken,
                price,
                action: async (minAmount) => {
                    tx = async () => await sell(baseValue, minAmount, quote);
                    try {
                        await executeTransaction({
                            message: 'Executing Sell',
                            tx
                        });
                    } catch (error) {
                        console.log(error);
                    }
                    refreshPrice();
                    setShowConfirm(false);

                    refreshBalance();
                }
            });
        }

        setShowConfirm(true);
    };

    const handleApprove = async () => {
        const tx = async () => await approve();

        await executeTransaction({
            message: 'Approving',
            tx
        });
    };

    const checkValid = useCallback(() => {

        const baseValue = numericHandles.baseInput.getNumber();

        if (bnbBalance < 0.01 || (base === 'BNB' && bnbBalance - baseValue < 0.01)) {
            setErrorMessage(`Not enough balance left for gas.`);
            return false;
        }

        if (baseValue > balance) {
            setErrorMessage(`Insufficent balance`);
            return false;
        }
        setErrorMessage(null);
        return !!numericHandles.baseInput.getNumber() && !!numericHandles.baseInput.getNumber() > 0;
    }, [numericHandles, bnbBalance, setErrorMessage, balance]);

    const handleCoinSelect = useCallback((side) => (coin) => {
        if (side === "quote") {
            setQuote(coin);
        } else {
            setBase(coin);
        }
    }, [setBase, setQuote]);

    const toggleMode = () => {
        setQuote(base);
        setBase(quote);
    };

    useEffect(() => {
        if (!account || !connected) {
            return;
        }

        refreshBalance();

    }, [account, base, connected]);

    useEffect(() => {
        const [numericHandle1, numericHandle2] = AutoNumeric.multiple([baseInput.current, quoteInput.current], {
            decimalPlaces: 8,
            minimumValue: 0,
            allowDecimalPadding: false,
            emptyInputBehavior: 0,
            decimalCharacterAlternative: ','
        });

        setnumericHandles({
            baseInput: numericHandle1,
            quoteInput: numericHandle2
        });

    }, [baseInput, quoteInput]);


    const updateInput = useCallback((source, target) => () => {
        if (!numericHandles) {
            return;
        }
        const value = numericHandles[source].getNumber();
        let inverted = (base !== 'HPAY' && source === 'baseInput') || (quote !== 'HPAY' && source === 'quoteInput');
        inverted = base === "HPAY" ? inverted : !inverted;
        const relativeValue = new BigNumber(value * Math.pow(price, inverted ? -1 : 1)).toFixed(6, 1);


        if (!!relativeValue) {
            numericHandles[target].set(+relativeValue);
        } else {
            numericHandles[target].clear(true);
        }

        setIsValid(checkValid());
    }, [base, checkValid, numericHandles, price]);

    const setMax = useCallback(() => {
        numericHandles.baseInput.set(new BigNumber(balance).toFixed(6, 1));
        updateInput('baseInput', 'quoteInput')();
    }, [updateInput, numericHandles, balance]);

    const ActionButton = () => {
        if (isApproved) {
            return (<Button
                disabled={!connected || !isValid || !isBsc || !!pendingTransaction}
                onClick={swap} className="mb-2" block>{
                    quote === "HPAY" ? "Buy" : "Sell"}</Button>);
        } else {
            return (<Button disabled={!connected || !isBsc || !!pendingTransaction}
                onClick={handleApprove} className="mb-2" block>Approve</Button>);
        }
    };

    return (
        <>
            <Card className="donate-component">
                <Card.Body>
                    <Row style={{ marginBottom: '1.5rem' }} className="align-items-center">
                        <Col md={12} className="text-lg-left mb-3">
                            <h3 className="mb-0 text-white text-center text-lg-left">Swap HedgePay</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form>
                                <Form.Row className="justify-content-center no-gutters">

                                    <Form.Group as={Col} md="12" className="staking-control" controlId="validationFormik01">
                                        <div className="d-flex justify-content-between">
                                            <Form.Label className="balance-label">Spending {numericHandles && base === 'BNB' && '~$' + new BigNumber(numericHandles.baseInput.getNumber()).times(bnbPrice).toFixed(2, 1)} </Form.Label>
                                            <Form.Label className="text-right balance-label">
                                                Balance <NumberFormat value={balance} displayType={'text'} thousandSeparator={true} />
                                            </Form.Label>
                                        </div>
                                        <InputGroup className="mb-0">
                                            <Form.Control
                                                className="staking-input mb-0"
                                                type="text"
                                                autoComplete="off"
                                                inputMode="decimal"
                                                ref={baseInput}
                                                name="baseInput"
                                                onChange={updateInput('baseInput', 'quoteInput')}
                                            />
                                            <InputGroup.Append className="staking-input-append mb-0">
                                                <span
                                                    role="button" tabIndex={0}
                                                    onKeyPress={setMax}
                                                    onClick={setMax} className="staking-input-max clickable">
                                                    MAX
                                                </span>
                                                {base !== "HPAY" ? <CoinSelect disabledCoins={disabledCoins}
                                                    defaultSelected={base}
                                                    onSelect={handleCoinSelect('base')}></CoinSelect> : <span className="text-primary-gradient">HPAY</span>}
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                    <Button className="rounded-pill btn-swipe"
                                        disabled={pendingTransaction}
                                        onClick={toggleMode}>
                                        <svg viewBox="0 0 24 25" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" className=""><path d="M16 17.01V11C16 10.45 15.55 10 15 10C14.45 10 14 10.45 14 11V17.01H12.21C11.76 17.01 11.54 17.55 11.86 17.86L14.65 20.64C14.85 20.83 15.16 20.83 15.36 20.64L18.15 17.86C18.47 17.55 18.24 17.01 17.8 17.01H16ZM8.65003 3.35002L5.86003 6.14002C5.54003 6.45002 5.76003 6.99002 6.21003 6.99002H8.00003V13C8.00003 13.55 8.45003 14 9.00003 14C9.55003 14 10 13.55 10 13V6.99002H11.79C12.24 6.99002 12.46 6.45002 12.14 6.14002L9.35003 3.35002C9.16003 3.16002 8.84003 3.16002 8.65003 3.35002Z"></path></svg> </Button>
                                    <Form.Group as={Col} md="12" className="staking-control" controlId="validationFormik01">
                                        <div className="d-flex justify-content-between">
                                            <Form.Label className="balance-label">Will Receive</Form.Label>
                                        </div>
                                        <InputGroup className="mb-0">
                                            <Form.Control
                                                className="staking-input mb-0"
                                                type="text"
                                                autoComplete="off"
                                                inputMode="decimal"
                                                ref={quoteInput}
                                                name="quoteInput"
                                                onChange={updateInput('quoteInput', 'baseInput')}
                                            />
                                            <InputGroup.Append className="staking-input-append mb-0 ">
                                                {quote !== "HPAY" ? <CoinSelect disabledCoins={disabledCoins}
                                                    defaultSelected={quote}
                                                    onSelect={handleCoinSelect('quote')}></CoinSelect> : <span className="text-primary-gradient">HPAY</span>}
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" className="mt-4 px-0 mb-0 ">
                                        <ActionButton />
                                        {errorMessage && <p className="mb-0 text-center">{errorMessage}</p>}
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Col>

                    </Row>
                </Card.Body>
            </Card >

            <Modal
                className="stake-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={!!showConfirm} onHide={handleClose} >

                <Modal.Body>
                    <ExchangeConfirm  {...modalStatus}></ExchangeConfirm>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default Exchange;
