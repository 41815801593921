import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useInterval } from 'react-use';
import ChainIcon from '../images/chain-icon.png';
import ExpandIcon from '../images/expand-icon.png';
import TickerSym from '../images/ticker-sym.png';
import { Tokens, useTokenContract } from '../web3/contracts/contracts';
import { useHpayExchangePrice } from '../web3/exchange';

function PresaleStats() {
    const [price, setPrice] = useState(0);
    const [marketCap, setMarketCap] = useState(0);

    const fetchRates = useHpayExchangePrice();
    const { supply } = useTokenContract(Tokens.HPAY.address);

    useEffect(() => {
        fetchRates(Tokens.HPAY, Tokens.BUSD).then(setPrice);
    }, [setPrice, fetchRates]);

    useEffect(() => {
        setMarketCap(+(price * supply).toFixed(2));
    }, [price, setMarketCap, supply]);

    useInterval(async () => {
        const price = await fetchRates(Tokens.HPAY, Tokens.BUSD);
        setPrice(price);
    }, 10000);

    return (
        <Row className="h-100">
            <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>Ticker Symbol</p>
                    <h5><img src={TickerSym} alt="HedgePay Finance Ticker" /> HPAY</h5>
                </div>
            </Col>
            <Col xs={6} className="mb-3 text-right text-md-left">
                <div className="eco-group">
                    <p>Price</p>
                    <h5>$
                        <CountUp isCounting end={price || 0} decimals={8} preserveValue={true} separator=',' />
                    </h5>
                </div>
            </Col>

            <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>Contract Address</p>
                    <h5>0xc75aa...dfd521a
                        <a href="https://bscscan.com/address/0xc75aa1fa199eac5adabc832ea4522cff6dfd521a/" target="_blank" rel="noopener noreferrer">
                            <img src={ExpandIcon} className="ml-2 mr-0" alt="icon" />
                        </a>
                    </h5>
                </div>
            </Col>
            <Col xs={6} className="mb-3 text-right text-md-left">
                <div className="eco-group">
                    <p>Chain</p>
                    <h5><img src={ChainIcon} alt="icon" /> BSC (BEP-20)</h5>
                </div>
            </Col>
            <Col xs={6} className="mb-3">
                <div className="eco-group">
                    <p>Max Supply</p>
                    <h5>1,000,000,000 HPAY</h5>
                </div>
            </Col>
            <Col xs={6} className="mb-3 text-right text-md-left">
                <div className="eco-group">
                    <p>Circulating Supply</p>
                    <h5> <CountUp isCounting end={supply || 0} decimals={0} preserveValue={true} separator=',' /> HPAY</h5>
                </div>
            </Col>

            <Col xs={6} className="mb-3 text-left">
                <div className="eco-group">
                    <p>MarketCap</p>
                    <h5> $ <CountUp isCounting end={marketCap || 0} decimals={2} preserveValue={true} separator=',' /> </h5>
                </div>
            </Col>
        </Row>
    );
}

export default PresaleStats;
