import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Otc from "../containers/Otc";
import Countdown from 'react-countdown';

const startDate = Date.parse("8 Feb 2022 15:00:00 GMT");

const InvestPage = () => {
  const [showCountdown, setShowCountdown] = useState();
  useEffect(() => {
    setShowCountdown(Date.now() < startDate);
  }, []);


  return (
    <Layout pageInfo={{ pageName: "Invest" }}>
      <ScrollUpButton ContainerClassName="custom-scroll-button" />
      <Seo title="Invest" />
      <Container className="d-flex flex-column justify-content-around mt-5">
        <Row className="mb-md-5 mb-4">
          <Col md={6} >
            <h2 className="title-seperator">HedgePay Exchange</h2>
            <p>
              Buy and Sell HPAY tokens at a discounted fee rate.
            </p>
          </Col>
          <Col md={6} >

            {showCountdown && <div className="banner-info"><p className="mb-1 text-center">
              The HedgePay Exchange  will be live in
            </p>
              <h3 className="text-primary-gradient text-center m-0">
                <Countdown onComplete={() => {
                  showCountdown(false);
                }} key={startDate} date={Date.parse("8 Feb 2022 15:00:00 GMT")}></Countdown>
              </h3>
            </div>}
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={12} className="d-flex flex-column align-items-center header-text">
            <Otc />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default InvestPage;
